"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var rxjs_1 = require("rxjs");
var ajax_1 = require("rxjs/ajax");
var operators_1 = require("rxjs/operators");
var SearchListItem_1 = require("./SearchListItem");
var BaseStyle_1 = require("./BaseStyle");
var firebase_1 = require("./firebase");
var Header_1 = require("./Header");
var react_router_dom_1 = require("react-router-dom");
var react_notification_1 = require("react-notification");
var VoteService_1 = require("./VoteService");
var TokenService_1 = require("./TokenService");
var SearchState = /** @class */ (function () {
    function SearchState() {
    }
    return SearchState;
}());
exports.SearchState = SearchState;
function renderTrack(item) {
    var trackStyle = {
        color: BaseStyle_1.baseStyle.textColor,
        fontSize: "10pt"
    };
    var artistStyle = {
        color: BaseStyle_1.baseStyle.subTextColor,
        fontSize: "10pt"
    };
    return (React.createElement("div", null,
        React.createElement("div", { style: trackStyle }, item.name),
        React.createElement("div", { style: artistStyle }, item.artists.map(function (a) { return a.name; }).join(", "))));
}
exports.renderTrack = renderTrack;
var Search = /** @class */ (function (_super) {
    __extends(Search, _super);
    function Search(props) {
        var _this = _super.call(this, props) || this;
        _this.input = new rxjs_1.Subject();
        _this.voteService = new VoteService_1.VoteService();
        _this.input
            .pipe(operators_1.debounceTime(300), operators_1.map(function (i) {
            if (!i || i === "")
                return rxjs_1.of({});
            _this.setState({
                searching: true,
                limit: {
                    artists: 5,
                    tracks: 5,
                    albums: 5
                }
            });
            var url = "https://api.spotify.com/v1/search?type=album,artist,track&q=" +
                encodeURIComponent(i);
            return TokenService_1.tokenService.token.pipe(operators_1.first(), operators_1.flatMap(function (token) {
                return ajax_1.ajax({
                    url: url,
                    headers: {
                        authorization: "Bearer " + token.access_token
                    },
                    crossDomain: true,
                    method: "GET",
                    responseType: "json"
                });
            }), operators_1.map(function (i) { return i.response; }));
        }), operators_1.flatMap(function (i) { return i; }))
            .subscribe(function (r) {
            _this.setState({ response: r, searching: false });
        });
        _this.state = { q: "", response: null };
        _this.input.next("");
        return _this;
    }
    Object.defineProperty(Search.prototype, "eventId", {
        get: function () {
            return this.props.match.params.eventId;
        },
        enumerable: true,
        configurable: true
    });
    Search.prototype.voteStatus = function (id) {
        if (!this.state.votes)
            return true;
        if (this.state.votes[id])
            return true;
        return false;
    };
    Search.prototype.componentWillMount = function () {
        var _this = this;
        this.setState({ votes: {} });
        this.voteService
            .loadVoteStatus(this.props.match.params.eventId)
            .subscribe(function (votes) { return _this.setState({ votes: votes }); });
    };
    Search.prototype.loadMore = function (target) {
        var _this = this;
        var uri = this.state.response[target].next;
        if (!uri) {
            return;
        }
        this.state.response[target].next = null;
        TokenService_1.tokenService.token
            .pipe(operators_1.first(), operators_1.flatMap(function (token) {
            return ajax_1.ajax({
                url: uri,
                headers: {
                    authorization: "Bearer " + token.access_token
                },
                crossDomain: true,
                method: "GET",
                responseType: "json"
            });
        }), operators_1.map(function (i) { return i.response; }))
            .subscribe(function (r) {
            var _a;
            (_a = _this.state.response[target].items).push.apply(_a, r[target].items);
            _this.state.response[target].next = r[target].next;
            _this.state.limit[target] += r[target].items.length;
            _this.setState(_this.state);
        });
    };
    Search.prototype.addToPlaylist = function (item) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, firebase_1.app
                            .database()
                            .ref("/events/" + this.eventId + "/playlistQueue")
                            .push(item.id)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.voteService.vote(this.eventId, item.id)];
                    case 2:
                        _a.sent();
                        this.setState({ notification: true });
                        setTimeout(function () { return _this.setState({ notification: false }); }, 1000);
                        return [2 /*return*/];
                }
            });
        });
    };
    Search.prototype.handleChange = function (event) {
        var value = event.target.value;
        this.setState({ q: value });
        this.input.next(value);
    };
    Search.prototype.limit = function (items, count) {
        if (items.length < count)
            return items;
        return items.slice(0, count);
    };
    Search.prototype.render = function () {
        var _this = this;
        var loadMoreStyle = {
            textAlign: "center",
            fontSize: "13px",
            margin: "10px",
            height: "40px"
        };
        var artists = null;
        if (this.state.response && this.state.response.artists) {
            artists = (React.createElement("div", null,
                this.limit(this.state.response.artists.items, this.state.limit.artists).map(function (i) { return (React.createElement(react_router_dom_1.Link, { key: i.id, to: "/artist/" + _this.eventId + "/" + i.id },
                    React.createElement(SearchListItem_1.SearchListItem, { key: i.id, item: i }))); }),
                this.state.response.artists.next ? (React.createElement("div", { style: loadMoreStyle, onClick: function () { return _this.loadMore("artists"); } }, "Mehr anzeigen...")) : null));
        }
        var albums = null;
        if (this.state.response && this.state.response.albums) {
            albums = (React.createElement("div", null,
                this.limit(this.state.response.albums.items, this.state.limit.albums).map(function (i) { return (React.createElement(react_router_dom_1.Link, { key: i.id, to: "/album/" + _this.eventId + "/" + i.id },
                    React.createElement(SearchListItem_1.SearchListItem, { key: i.id, item: i, itemRenderer: function (i) { return renderTrack(i); } }))); }),
                this.state.response.albums.next ? (React.createElement("div", { style: loadMoreStyle, onClick: function () { return _this.loadMore("albums"); } }, "Mehr anzeigen...")) : null));
        }
        var tracks = null;
        if (this.state.response && this.state.response.tracks) {
            tracks = (React.createElement("div", null,
                this.limit(this.state.response.tracks.items, this.state.limit.tracks).map(function (i) { return (React.createElement(SearchListItem_1.SearchListItem, { key: i.id, item: i, clickEnabled: !_this.voteStatus(i.id), onClick: function () { return _this.addToPlaylist(i); }, itemRenderer: function (i) { return renderTrack(i); } })); }),
                this.state.response.tracks.next ? (React.createElement("div", { style: loadMoreStyle, onClick: function () { return _this.loadMore("tracks"); } }, "Mehr anzeigen...")) : null));
        }
        var style = {
        // display: "flex"
        };
        var styleElement = {
            flex: 1
        };
        var headerStyle = {
            textAlign: "center",
            fontSize: "13px",
            margin: "10px"
        };
        var inputStyle = {
            width: "100%",
            backgroundColor: "#666666",
            border: "1px solid black",
            padding: "10px",
            marginLeft: "5px"
        };
        var searchbarStyle = {
            paddingBottom: "10px",
            padding: "5px",
            display: "inline-flex",
            width: "100%"
        };
        var result = null;
        if (this.state.searching === false) {
            result = (React.createElement("div", { style: style },
                artists ? (React.createElement("div", { style: styleElement },
                    React.createElement("div", { style: headerStyle },
                        React.createElement("b", null, "Artist")),
                    artists)) : null,
                albums ? (React.createElement("div", { style: styleElement },
                    React.createElement("div", { style: headerStyle },
                        React.createElement("b", null, "Albums")),
                    albums)) : null,
                tracks ? (React.createElement("div", { style: styleElement },
                    React.createElement("div", { style: headerStyle },
                        React.createElement("b", null, "Tracks")),
                    tracks)) : null));
        }
        return (React.createElement("div", null,
            React.createElement(Header_1.Header, { icon: "plus", text: "Musik hinzuf\u00FCgen" }),
            React.createElement("div", { style: { width: "100%", display: "flex" } },
                React.createElement("div", { style: searchbarStyle },
                    React.createElement("div", { className: "icon icon-search" }),
                    React.createElement("input", { style: inputStyle, type: "text", value: this.state.q, onChange: function (e) { return _this.handleChange(e); } }))),
            result,
            React.createElement(react_notification_1.Notification, { isActive: this.state.notification, message: "Track hinzugef\u00FCgt" })));
    };
    return Search;
}(React.Component));
exports.Search = Search;
