"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var firebase_1 = require("./firebase");
var imageSelector_1 = require("./imageSelector");
var BaseStyle_1 = require("./BaseStyle");
var Playlist_1 = require("./Playlist");
var Header_1 = require("./Header");
var Event = /** @class */ (function (_super) {
    __extends(Event, _super);
    function Event(props) {
        return _super.call(this, props) || this;
    }
    Event.prototype.componentWillMount = function () {
        this.setState({});
        this.loadData(this.props.match.params.eventId);
    };
    Event.prototype.componentWillReceiveProps = function (nextProps) {
        this.loadData(nextProps.match.params.eventId);
    };
    Event.prototype.loadData = function (eventId) {
        var _this = this;
        var ref = firebase_1.app.database().ref('events/' + eventId + "/playing");
        var res = ref.on("value", function (snapshot) {
            _this.setState({ playing: snapshot.val() });
        });
    };
    Event.prototype.render = function () {
        if (!this.state.playing)
            return React.createElement("div", null);
        var images = null;
        if (this.state.playing.albumCover && this.state.playing.albumCover.length > 0) {
            images = React.createElement(imageSelector_1.ImageSelector, { images: this.state.playing.albumCover, width: 100 });
        }
        var elementStyle = {
            display: "flex",
            alignItems: "flex-end"
        };
        var imageStyle = {
            paddingRight: "10px"
        };
        var trackStyle = {
            color: BaseStyle_1.baseStyle.textColor,
            fontSize: "20pt",
            fontWeight: "bold"
        };
        var artistStyle = {
            color: BaseStyle_1.baseStyle.subTextColor
        };
        var blockStyle = {
            padding: 10,
        };
        return (React.createElement("div", null,
            React.createElement(Header_1.Header, { text: "Playlist", icon: "" }),
            React.createElement("div", { style: blockStyle },
                this.state.playing.running ? (React.createElement("div", { style: elementStyle },
                    React.createElement("div", { style: imageStyle }, images),
                    React.createElement("div", null,
                        this.state.playing.userId ? React.createElement("div", { style: artistStyle }, this.state.playing.userId) : null,
                        React.createElement("div", { style: trackStyle }, this.state.playing.track),
                        React.createElement("div", { style: artistStyle }, this.state.playing.artist)))) : null,
                React.createElement(Playlist_1.Playlist, { eventId: this.props.match.params.eventId }))));
    };
    return Event;
}(React.Component));
exports.Event = Event;
