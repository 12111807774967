"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var Login_1 = require("./Login");
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var Home_1 = require("./Home");
var Search_1 = require("./Search");
var Event_1 = require("./Event");
var Artist_1 = require("./Artist");
var Album_1 = require("./Album");
var BaseStyle_1 = require("./BaseStyle");
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    App.prototype.render = function () {
        var headerStyle = {
            display: "flex",
            padding: "10px",
            backgroundColor: "#262626"
        };
        var logoStyle = {
            backgroundImage: "url('/assets/logo.png')",
            backgroundRepeat: "no-repeat",
            height: "40px",
            width: "80px"
        };
        var headerTextStyle = {
            fontWeight: "bold"
        };
        var headerTextSecondStyle = {
            fontWeight: "bold",
            color: BaseStyle_1.baseStyle.subTextColor
        };
        var ulStyle = {
            listStyleType: "none",
            margin: 0,
            padding: 0,
            display: "flex",
            backgroundColor: BaseStyle_1.baseStyle.backgroundColor,
            borderTop: "1px solid rgb(102, 102, 102)",
            borderBottom: "1px solid rgb(102, 102, 102)",
        };
        var liStyle = {
            float: "left",
            padding: "10px",
            borderRight: "1px solid rgb(102, 102, 102)"
        };
        var liHighlightStyle = {
            backgroundColor: BaseStyle_1.baseStyle.backgroundColorLight
        };
        return (React.createElement(react_router_dom_1.HashRouter, null,
            React.createElement("div", null,
                React.createElement("div", { style: headerStyle },
                    React.createElement("div", { style: logoStyle }),
                    React.createElement("div", null,
                        React.createElement("div", { style: headerTextStyle }, "SILENTPARTY.CH"),
                        React.createElement("div", { style: headerTextSecondStyle }, "LIVE KANAL"))),
                React.createElement("ul", { style: ulStyle },
                    React.createElement("li", null,
                        React.createElement(react_router_dom_1.NavLink, { to: "/", style: liStyle, exact: true, activeStyle: liHighlightStyle },
                            React.createElement("div", { className: "icon icon-flag" }))),
                    React.createElement("li", null,
                        React.createElement(react_router_dom_1.NavLink, { to: "/event/2", style: liStyle, exact: true, activeStyle: liHighlightStyle },
                            React.createElement("div", { className: "icon icon-playlist" }))),
                    React.createElement("li", null,
                        React.createElement(react_router_dom_1.NavLink, { to: "/search/2", style: liStyle, exact: true, activeStyle: liHighlightStyle },
                            React.createElement("div", { className: "icon icon-search" })))),
                React.createElement(react_router_dom_1.Route, { exact: true, path: "/", component: Home_1.Home }),
                React.createElement(react_router_dom_1.Route, { path: "/event/:eventId", component: Event_1.Event }),
                React.createElement(react_router_dom_1.Route, { path: "/search/:eventId", component: Search_1.Search }),
                React.createElement(react_router_dom_1.Route, { path: "/artist/:eventId/:artistId", component: Artist_1.Artist }),
                React.createElement(react_router_dom_1.Route, { path: "/album/:eventId/:albumId", component: Album_1.Album }),
                React.createElement(react_router_dom_1.Route, { path: "/login/:eventId", component: Login_1.Login }))));
    };
    return App;
}(React.Component));
exports.App = App;
