"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var firebase_1 = require("./firebase");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var ajax_1 = require("rxjs/ajax");
var TokenService = /** @class */ (function () {
    function TokenService() {
        var _this = this;
        this._refreshInterval = firebase_1.settings.tokenRefreshInterval;
        this._tokenEndpoint = firebase_1.settings.tokenEndpoint;
        this._token = rxjs_1.of(1).pipe(operators_1.concat(rxjs_1.interval(this._refreshInterval * 1000)), operators_1.flatMap(function (i) {
            return ajax_1.ajax({
                url: _this._tokenEndpoint,
                crossDomain: true,
                method: "GET",
                responseType: "json"
            });
        }), operators_1.map(function (i) { return i.response; }), operators_1.publishReplay(1), operators_1.refCount());
        this._token.subscribe();
    }
    Object.defineProperty(TokenService.prototype, "token", {
        get: function () {
            return this._token;
        },
        enumerable: true,
        configurable: true
    });
    return TokenService;
}());
exports.tokenService = new TokenService();
