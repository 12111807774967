"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var imageSelector_1 = require("./imageSelector");
var SearchListItem = /** @class */ (function (_super) {
    __extends(SearchListItem, _super);
    function SearchListItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SearchListItem.prototype.render = function () {
        var _this = this;
        var style = {
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #000",
            paddingTop: "3px",
            paddingBottom: "3px",
        };
        var imageWidth = 40;
        var imageStyle = {
            marginRight: "10px",
            width: imageWidth + "px",
            height: imageWidth + "px",
        };
        var iconStyle = {
            cursor: "pointer",
            marginRight: "10px"
        };
        var iconStyleDisabled = {
            marginRight: "10px"
        };
        var image = null;
        if (this.props.item.images && this.props.item.images.length > 0) {
            image = React.createElement("div", { style: imageStyle },
                React.createElement(imageSelector_1.ImageSelector, { images: this.props.item.images, width: imageWidth }));
        }
        var likeIcon = null;
        if (this.props.onClick) {
            if (this.props.clickEnabled) {
                likeIcon = React.createElement("div", { className: "icon icon-thumb", style: iconStyle, onClick: function () { return _this.props.onClick(); } });
            }
            else {
                likeIcon = React.createElement("div", { className: "icon icon-thumb icon-transparent", style: iconStyleDisabled });
            }
        }
        var content = this.props.item.name;
        if (this.props.itemRenderer)
            content = this.props.itemRenderer(this.props.item);
        return (React.createElement("div", { style: style },
            image,
            likeIcon,
            React.createElement("div", null, content)));
    };
    return SearchListItem;
}(React.Component));
exports.SearchListItem = SearchListItem;
