"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var firebase = require("firebase");
// dev
// var config = {
//   apiKey: "AIzaSyC4h0elkQFMLxvg22EI5-H2FFchvk2-wNM",
//   authDomain: "silentparty-dev.firebaseapp.com",
//   databaseURL: "https://silentparty-dev.firebaseio.com",
//   storageBucket: "silentparty-dev.appspot.com",
//   messagingSenderId: "575846821411"
// };
// export const settings = {
//   tokenEndpoint: "https://us-central1-silentparty-dev.cloudfunctions.net/getToken",
//   tokenRefreshInterval: 60
// };
// dev new
// var config = {
//   apiKey: "AIzaSyAnfzChaHg6Pzj3ENMEPvNBNlqvWkEYicY",
//   authDomain: "quantum-ether-216.firebaseapp.com",
//   databaseURL: "https://quantum-ether-216.firebaseio.com",
//   projectId: "quantum-ether-216",
//   storageBucket: "quantum-ether-216.appspot.com",
//   messagingSenderId: "654016727266"
// };
// export const settings = {
//   tokenEndpoint: "https://us-central1-silentparty-dev.cloudfunctions.net/getToken",
//   tokenRefreshInterval: 60,
//   loginEndpoint: "https://live.meta-db.com/authentication"
// };
// pd
var config = {
    apiKey: "AIzaSyAN8MzEf_GQrBsUqDNqt9IwzmKJ9vkpC2I",
    authDomain: "silentpart-a9d9d.firebaseapp.com",
    databaseURL: "https://silentpart-a9d9d.firebaseio.com",
    projectId: "silentpart-a9d9d",
    storageBucket: "silentpart-a9d9d.appspot.com",
    messagingSenderId: "574194654993"
};
exports.settings = {
    tokenEndpoint: "https://us-central1-silentpart-a9d9d.cloudfunctions.net/getToken",
    tokenRefreshInterval: 3000,
    loginEndpoint: "https://live.meta-db.com/authentication"
};
exports.app = firebase.initializeApp(config);
