"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var firebase_1 = require("./firebase");
var BaseStyle_1 = require("./BaseStyle");
var PlaylistProps = /** @class */ (function () {
    function PlaylistProps() {
    }
    return PlaylistProps;
}());
exports.PlaylistProps = PlaylistProps;
var PlaylistItem = /** @class */ (function () {
    function PlaylistItem(id, track, trackId, artist, artistId, votes, played, userId) {
        this.id = id;
        this.track = track;
        this.trackId = trackId;
        this.artist = artist;
        this.artistId = artistId;
        this.votes = votes;
        this.played = played;
        this.userId = userId;
    }
    return PlaylistItem;
}());
var PlaylistState = /** @class */ (function () {
    function PlaylistState() {
    }
    return PlaylistState;
}());
exports.PlaylistState = PlaylistState;
var Playlist = /** @class */ (function (_super) {
    __extends(Playlist, _super);
    function Playlist(props) {
        return _super.call(this, props) || this;
    }
    Playlist.prototype.componentWillMount = function () {
        this.setState({ playlist: [] });
        this.loadData(this.props.eventId);
        this.loadVoteStatus();
    };
    Playlist.prototype.componentWillUnmount = function () {
        this.ref.off("child_added");
        this.ref.off("child_removed");
        this.ref.off("child_changed");
    };
    Playlist.prototype.componentWillReceiveProps = function (nextProps) {
        this.loadData(nextProps.eventId);
        this.loadVoteStatus();
    };
    Playlist.prototype.loadData = function (eventId) {
        var _this = this;
        if (this.ref) {
            this.ref.off("child_added");
            this.ref.off("child_removed");
            this.ref.off("child_changed");
        }
        this.setState({ playlist: [] });
        this.ref = firebase_1.app.database().ref('events/' + eventId + "/playlist");
        this.ref.on("child_added", function (data) {
            var item = data.val();
            item.id = data.key;
            item.votes = item.votes || 0;
            _this.state.playlist.push(item);
            _this.updatePlaylist();
        });
        this.ref.on("child_removed", function (data) {
            var item = data.val();
            item.id = data.key;
            var index = _this.state.playlist.findIndex(function (i) { return i.id == data.key; });
            _this.state.playlist.splice(index, 1);
            _this.updatePlaylist();
        });
        this.ref.on("child_changed", function (data) {
            var item = data.val();
            item.id = data.key;
            item.votes = item.votes || 0;
            var index = _this.state.playlist.findIndex(function (i) { return i.id == data.key; });
            _this.state.playlist[index] = item;
            _this.updatePlaylist();
        });
    };
    Playlist.prototype.updatePlaylist = function () {
        this.state.playlist.sort(function (i1, i2) { return i2.votes - i1.votes; });
        this.state.playlist = this.state.playlist.filter(function (i) { return !i.played; });
        this.setState({ playlist: this.state.playlist });
    };
    Playlist.prototype.voteItem = function (item) {
        return __awaiter(this, void 0, void 0, function () {
            var user, ref;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.allowVote(item.trackId))
                            return [2 /*return*/];
                        return [4 /*yield*/, this.getUser()];
                    case 1:
                        user = _a.sent();
                        item.votes++;
                        this.updatePlaylist();
                        ref = firebase_1.app.database().ref('votes/' + this.props.eventId + "/" + user.uid + "/" + item.id);
                        return [4 /*yield*/, ref.set(true)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Playlist.prototype.loadVoteStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var user, ref;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUser()];
                    case 1:
                        user = _a.sent();
                        ref = firebase_1.app.database().ref('votes/' + this.props.eventId + "/" + user.uid)
                            .on("value", function (snapshot) {
                            _this.setState({ votes: snapshot.val() });
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Playlist.prototype.allowVote = function (key) {
        if (!this.state.votes)
            return false;
        if (this.state.votes[key])
            return true;
        return false;
    };
    Playlist.prototype.getUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            var user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        user = firebase_1.app.auth().currentUser;
                        if (!!user) return [3 /*break*/, 2];
                        return [4 /*yield*/, firebase_1.app.auth().signInAnonymously()];
                    case 1:
                        user = _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/, user];
                }
            });
        });
    };
    Playlist.prototype.render = function () {
        var _this = this;
        if (!this.state.playlist)
            return null;
        var boxStyle = {
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #000",
            paddingTop: "3px",
            paddingBottom: "3px",
        };
        var trackStyle = {
            color: BaseStyle_1.baseStyle.textColor,
            fontSize: "10pt"
        };
        var artistStyle = {
            color: BaseStyle_1.baseStyle.subTextColor,
            fontSize: "10pt"
        };
        var voteStyle = {
            color: BaseStyle_1.baseStyle.highlightColor,
            fontSize: "10pt",
            width: "50px"
        };
        var voteButtonStyle = {
            width: "50px"
        };
        var res = [];
        var _loop_1 = function (item) {
            res.push(React.createElement("div", { style: boxStyle, key: item.id, onClick: function () { return _this.voteItem(item); } },
                React.createElement("div", { style: voteButtonStyle },
                    React.createElement("div", { className: "icon icon-thumb" + (this_1.allowVote(item.id) ? " icon-transparent" : "") })),
                React.createElement("div", { style: voteStyle }, item.votes || 0),
                React.createElement("div", null,
                    item.userId ? React.createElement("div", { style: artistStyle }, item.userId) : null,
                    React.createElement("div", { style: trackStyle }, item.track),
                    React.createElement("div", { style: artistStyle }, item.artist))));
        };
        var this_1 = this;
        for (var _i = 0, _a = this.state.playlist; _i < _a.length; _i++) {
            var item = _a[_i];
            _loop_1(item);
        }
        return React.createElement("div", null, res);
    };
    return Playlist;
}(React.Component));
exports.Playlist = Playlist;
