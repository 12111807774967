"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var ImageSelector = /** @class */ (function (_super) {
    __extends(ImageSelector, _super);
    function ImageSelector() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ImageSelector.prototype.render = function () {
        if (!this.props.images)
            return null;
        var image;
        var size;
        for (var i = 0; i < this.props.images.length; i++) {
            var tmp = this.props.images[i];
            if (!size || (tmp.width < size && tmp.width >= this.props.width)) {
                image = tmp;
                size = tmp.width;
            }
        }
        var style = {
            width: this.props.width,
            height: this.props.width,
            overflow: "hidden"
        };
        if (!image || !image.url)
            return null;
        return (React.createElement("img", { src: image.url, style: style }));
    };
    return ImageSelector;
}(React.Component));
exports.ImageSelector = ImageSelector;
