"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var ajax_1 = require("rxjs/ajax");
var SearchListItem_1 = require("./SearchListItem");
var Header_1 = require("./Header");
var react_router_dom_1 = require("react-router-dom");
var TokenService_1 = require("./TokenService");
var operators_1 = require("rxjs/operators");
var ArtistResponse = /** @class */ (function () {
    function ArtistResponse() {
    }
    return ArtistResponse;
}());
exports.ArtistResponse = ArtistResponse;
var Artist = /** @class */ (function (_super) {
    __extends(Artist, _super);
    function Artist(props) {
        return _super.call(this, props) || this;
    }
    Object.defineProperty(Artist.prototype, "eventId", {
        get: function () {
            return this.props.match.params.eventId;
        },
        enumerable: true,
        configurable: true
    });
    Artist.prototype.componentWillMount = function () {
        this.setState({});
        this.loadData(this.props.match.params.artistId);
    };
    Artist.prototype.componentWillReceiveProps = function (nextProps) {
        this.loadData(nextProps.match.params.artistId);
    };
    Artist.prototype.loadData = function (artistId) {
        var _this = this;
        var url = "https://api.spotify.com/v1/artists/" + artistId;
        TokenService_1.tokenService.token
            .pipe(operators_1.first(), operators_1.flatMap(function (token) {
            return ajax_1.ajax({
                url: url,
                headers: {
                    authorization: "Bearer " + token.access_token
                },
                crossDomain: true,
                method: "GET",
                responseType: "json"
            });
        }))
            .subscribe(function (r) {
            _this.setState({ response: r.response });
        });
        var urlAlbums = "https://api.spotify.com/v1/artists/" + artistId + "/albums";
        TokenService_1.tokenService.token
            .pipe(operators_1.first(), operators_1.flatMap(function (token) {
            return ajax_1.ajax({
                url: urlAlbums,
                headers: {
                    authorization: "Bearer " + token.access_token
                },
                crossDomain: true,
                method: "GET",
                responseType: "json"
            });
        }))
            .subscribe(function (r) {
            _this.setState({ albums: r.response.items });
        });
    };
    Artist.prototype.render = function () {
        var _this = this;
        var style = {
        // display: "flex"
        };
        var styleElement = {
            flex: 1
        };
        var headerStyle = {
            textAlign: "center",
            fontSize: "13px",
            margin: "10px"
        };
        var body = null;
        var albums = null;
        if (this.state.albums) {
            albums = (React.createElement("div", null, this.state.albums.map(function (i) { return (React.createElement(react_router_dom_1.Link, { key: i.id, to: "/album/" + _this.eventId + "/" + i.id },
                React.createElement(SearchListItem_1.SearchListItem, { item: i }))); })));
        }
        if (this.state.response) {
            body = (React.createElement("div", { style: style },
                React.createElement("div", { style: styleElement },
                    React.createElement("div", { style: headerStyle },
                        React.createElement("b", null, this.state.response.name)),
                    albums)));
        }
        return (React.createElement("div", null,
            React.createElement(Header_1.Header, { icon: "plus", text: "Musik hinzuf\u00FCgen" }),
            body));
    };
    return Artist;
}(React.Component));
exports.Artist = Artist;
