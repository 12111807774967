"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var HeaderProps = /** @class */ (function () {
    function HeaderProps() {
    }
    return HeaderProps;
}());
exports.HeaderProps = HeaderProps;
var Header = /** @class */ (function (_super) {
    __extends(Header, _super);
    function Header(props) {
        return _super.call(this, props) || this;
    }
    Header.prototype.render = function () {
        var iconStyle = {
            marginRight: "17px"
        };
        return (React.createElement("div", { className: "header-container" },
            React.createElement("div", { className: "header" },
                React.createElement("div", { style: iconStyle, className: "header-icon icon icon-" + this.props.icon }),
                this.props.text)));
    };
    return Header;
}(React.Component));
exports.Header = Header;
