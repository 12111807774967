"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var firebase_1 = require("./firebase");
var Login = /** @class */ (function (_super) {
    __extends(Login, _super);
    function Login() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Login.prototype, "eventId", {
        get: function () {
            return this.props.match.params.eventId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Login.prototype, "status", {
        get: function () {
            if (this.state && this.state.status) {
                return this.state.status;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Login.prototype.componentWillMount = function () {
        var _this = this;
        firebase_1.app.database().ref('events/' + this.eventId + "/status")
            .on("value", function (snapshot) {
            var val = snapshot.val();
            if (!val) {
                val = false;
            }
            _this.setState({ status: val });
        });
    };
    Login.prototype.componentWillReceiveProps = function () {
    };
    Login.prototype.login = function () {
        window.location.href = firebase_1.settings.loginEndpoint;
    };
    Login.prototype.toggle = function () {
        firebase_1.app.database()
            .ref('events/' + this.eventId + "/status")
            .set(!this.status);
    };
    Login.prototype.render = function () {
        return (React.createElement("div", null,
            React.createElement("button", { onClick: this.login.bind(this) }, "Login with Spotify"),
            React.createElement("button", { onClick: this.toggle.bind(this) }, !this.status ? "Start" : "Stop")));
    };
    return Login;
}(React.Component));
exports.Login = Login;
