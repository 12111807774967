"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var Header_1 = require("./Header");
var BaseStyle_1 = require("./BaseStyle");
var Home = /** @class */ (function (_super) {
    __extends(Home, _super);
    function Home(props) {
        return _super.call(this, props) || this;
    }
    Home.prototype.render = function () {
        // const events = [{
        //     id: "event1",
        //     titel: "Stadtmuseum Aarau",
        //     place: "Aarau",
        //     date: new Date("2017-04-21")
        // }];
        var events = [
            {
                id: "2",
                titel: "Stadtmuseum Aarau",
                place: "29.04.2023"
            }
        ];
        var boxStyle = {
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #000",
            paddingTop: "3px",
            paddingBottom: "3px",
        };
        var trackStyle = {
            color: BaseStyle_1.baseStyle.textColor,
            fontSize: "10pt"
        };
        var artistStyle = {
            color: BaseStyle_1.baseStyle.subTextColor,
            fontSize: "10pt"
        };
        var voteStyle = {
            color: BaseStyle_1.baseStyle.highlightColor,
            fontSize: "10pt",
            width: "50px"
        };
        var voteButtonStyle = {
            width: "50px"
        };
        return (React.createElement("div", null,
            React.createElement(Header_1.Header, { text: "Veranstaltung", icon: "flag" }),
            React.createElement("div", null, events.map(function (item) {
                return (React.createElement(react_router_dom_1.Link, { key: item.id, to: "event/" + item.id },
                    React.createElement("div", { style: boxStyle },
                        React.createElement("div", { style: voteButtonStyle },
                            React.createElement("div", { className: "icon icon-hand" })),
                        React.createElement("div", null,
                            React.createElement("div", { style: trackStyle }, item.titel),
                            React.createElement("div", { style: artistStyle }, item.place)))));
            }))));
    };
    return Home;
}(React.Component));
exports.Home = Home;
